import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const newsCarousels = document.querySelectorAll('.carrousel-news-block');

  if (0 < newsCarousels.length) {
    newsCarousels.forEach(function (newsCarousel) {
      const isNoisy = newsCarousel.classList.contains('noisy-ear'); // Vérifiez si l'élément a la classe .noisy-ear

      const swiperConfig = {
        draggable: true,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 16,
        a11y: {
          paginationBulletMessage: '',
          nextSlideMessage: 'Prochaine nouvelle',
          prevSlideMessage: 'Nouvelle précédente',
        },
        navigation: {
          nextEl: newsCarousel.querySelector(
            '.news-block__nav.swiper-button-next'
          ),
          prevEl: newsCarousel.querySelector(
            '.news-block__nav.swiper-button-prev'
          ),
        },
      };

      // Ajoutez le point de rupture spécifique si l'élément est noisy-ear
      if (isNoisy) {
        swiperConfig.breakpoints = {
          600: {
            slidesPerView: 2,
          },
          769: {
            slidesPerView: 2.6,
          },
          1200: {
            slidesPerView: 3.635,
          },
        };
      } else {
        swiperConfig.breakpoints = {
          600: {
            slidesPerView: 2,
          },
          769: {
            slidesPerView: 2.6,
          },
          1024: {
            slidesPerView: 3.635,
          },
          1200: {
            slidesPerView: 4.635,
          },
        };
      }

      new Swiper(
        newsCarousel.querySelector('.carrousel-news-block__carousel'),
        swiperConfig
      );
    });
  }
});
